@import url("https://rsms.me/inter/inter.css");
html {
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", system-ui, -apple-system, BlinkMacSystemFont,
      Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
      Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  }
}

*,
:after,
:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html body {
  overscroll-behavior: none;
  font-size: 16px;
  color: #171e26;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html .bp3-tabs {
  width: 100%;
}

html .bp3-tab-list {
  margin-left: 27px;
  width: 100%;
}

html .bp3-tab-list:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  margin-left: -27px;
  border-bottom: 1px solid #dfe1e1;
}

html .bp3-tab-indicator-wrapper .bp3-tab-indicator {
  height: 2px;
  background-color: #2d62ae;
}

html .bp3-tab {
  line-height: 38px;
}

html .bp3-tab[aria-selected="true"],
html .bp3-tab:not([aria-disabled="true"]):hover {
  color: #2d62ae;
}
